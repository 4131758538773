<template>
	<div>
		<div class='row'>
			<div class='col-md-6'>
				<div class='left-side card-body' :class='{"light": lightmode}'>
					<img class='logo-img election-logo pl-0' :src='election.details.logo' v-if='election.details !== undefined && election.details.logo.length > 0'> 

					<h4 class="card-title mt-4">{{election.details.name}}</h4>
					<h6 class="card-subtitle mb-4">{{election.details.description}}</h6>

				</div>
			</div>
			<div class='col-md-6'>
				<div class='right-side card-body' :class='{"light": lightmode}'>
					<div class='inv-blokc d-none d-md-block'>
						&nbsp;
					</div>
					
					<SuccessSplash message="Thank you! Your selections have been registered." />
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang='scss'>
	.left-side{
		.upper{
			text-transform: uppercase;
		}
		.election-logo{
			max-width: 200px;
		}
		.card-title{
			font-size: 3rem;
		}
		*{
			color: white;
		}
		.grey{
			color: #BBB;
		}

		&.light{
			*{
				color: #000;
			}
		}
	}
	.right-side{
		* {
			color: white;
		}
		.inv-blokc{
			height: 120px;
		}
		label{
			font-size: 1.5rem;
		}
		input{
			background-color: white;
			border: 1px solid #666;
			border-radius: 5px;
			color: #000 !important;
			font-size: 18px;
		}

		*.light{
			color: #000;
		}
	}
</style>

<script>
import { mapGetters } from 'vuex';

import SuccessSplash from "@/components/General/SuccessSplash";

export default {
	name: 'Finished',
	components: {
		SuccessSplash
	},
	computed: {
		lightmode(){
			return this.branding.lightmode;
		},
		...mapGetters(['election', 'branding'])
	}
}
</script>